import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { BookableResourceWithEvents } from '../types/bookableResource';
import { useApiClient } from './useApiClient';
import { useBookableResources } from './useBookableResources';
import { useCallback, useMemo } from 'react';
import { useServices } from './useServices';
import { useUnits } from './useUnits';
import { useConnectors } from './useConnectors';

const ONE_MINUTE = 60 * 1000;

interface EventsFetchInfo {
  name: string;
  connectorId: string;
  resourceId: string;
}

export const useBookingResourcesWithEvents = (): UseQueryResult<
  BookableResourceWithEvents[]
> => {
  const apiClient = useApiClient();
  const { data: resources = [] } = useBookableResources();
  const { data: services } = useServices();
  const { data: units } = useUnits();
  const { data: connectors } = useConnectors();

  const eventsToFetch = useMemo((): EventsFetchInfo[] => {
    const list: EventsFetchInfo[] = [];
    services.forEach((s) => {
      const resource = resources.find(
        (r) => r.resource_source_id === s.booking_resource_id,
      );
      if (resource) {
        list.push({
          name: resource.name,
          connectorId:
            connectors?.find((c) => c.driver_type === s.connector_driver_type)
              ?.id ?? '',
          resourceId: s.booking_resource_id ?? '',
        });
      }
    });
    units.forEach((u) => {
      const resource = resources.find(
        (r) => r.resource_source_id === u.booking?.resource_id,
      );
      if (
        resource &&
        !list.find((e) => e.resourceId === u.booking?.resource_id)
      ) {
        list.push({
          name: resource.name,
          connectorId:
            connectors?.find(
              (c) => c.driver_type === u.booking?.connector_driver_type,
            )?.id ?? '',
          resourceId: u.booking?.resource_id ?? '',
        });
      }
    });
    return list;
  }, [connectors, resources, services, units]);

  const fetchAllEvents = useCallback(async (): Promise<
    BookableResourceWithEvents[]
  > => {
    const result: BookableResourceWithEvents[] = eventsToFetch.map((e) => ({
      name: e.name,
      resource_source_id: e.resourceId,
      events: [],
    }));

    if (!connectors) {
      return result;
    }

    await Promise.all(
      eventsToFetch.map(
        async ({ connectorId, resourceId }, index): Promise<void> => {
          const events = await apiClient.public.connectors.getResourceBookings(
            connectorId,
            resourceId,
          );

          result[index].events = events;
        },
      ),
    );

    return result;
  }, [apiClient.public.connectors, connectors, eventsToFetch]);

  return useQuery({
    queryKey: ['booking', 'resources', 'events', eventsToFetch],
    queryFn: () => fetchAllEvents(),
    refetchInterval: ONE_MINUTE,
  });
};

export const isCurrentlyUsed = (
  resource: BookableResourceWithEvents,
): boolean => {
  const now = new Date();
  return resource.events.some(
    ({ from, to }) => now > new Date(from) && now < new Date(to),
  );
};
