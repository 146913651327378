import { useMemo } from 'react';
import { Floor } from '../types/floor';
import useConfig from './useConfig';
import { useApartments } from './useApartments';
import { convertToNewUnitSystem } from '../utils/TenantUtils';
import { ddFloors } from '../gallery/dummydata/ddFloors';

export const useFloors = (): { data: Floor[] } => {
  const { organization_id } = useConfig();
  const { data: apartments } = useApartments();

  const floors = useMemo((): Floor[] => {
    if (organization_id === 'kruthusen') {
      return ddFloors;
    }

    return convertToNewUnitSystem(apartments).floors;
  }, [apartments, organization_id]);

  return { data: floors };
};
