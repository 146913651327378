import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useLocation } from './useLocation';
import { useConfig } from '../providers/ConfigProvider';
import { ApiStop, StopData } from '../types/publicTransits';
import axios from 'axios';
import { useIdentity } from '../providers/IdentityProvider';

export const usePublicTransitStops = (): UseQueryResult<StopData> => {
  const {
    apis: {
      publicTranitsQuery: {
        baseUrl,
        closestStopsLimit,
        departureFetchInterval,
      },
    },
  } = useConfig();
  const { data: location } = useLocation();
  const { id } = useIdentity();

  return useQuery({
    queryKey: [
      'publicTransit',
      'stops',
      location?.coordinate,
      baseUrl,
      closestStopsLimit,
      id,
    ],
    queryFn: async ({ queryKey: [, , coordinate] }): Promise<StopData> => {
      if (!coordinate || typeof coordinate !== 'object') {
        return Promise.resolve({});
      }

      const res = await axios.request<ApiStop[]>({
        url: `${baseUrl}/stops`,
        method: 'get',
        params: {
          lon: coordinate.x,
          lat: coordinate.y,
          limit: closestStopsLimit,
          origin: id,
        },
      });

      const stopData: StopData = {};
      res.data.forEach((s) => {
        stopData[s.stop_id] = {
          id: s.stop_id,
          name: s.stop_name,
          coordinate: { lat: s.stop_lat, lng: s.stop_lon },
        };
      });
      return stopData;
    },
    refetchInterval: departureFetchInterval,
    enabled: !!location?.coordinate,
  });
};
