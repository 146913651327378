import { ApiService2, reviveService } from '../../types/service';

export const ddApiServices: ApiService2[] = [
  {
    id: 'service_001',
    area_id: 'area_1',
    name: 'Laddstationer',
    equipment: [],
    type: 'charging_station',
    photo: undefined,
    description: 'Elbilsladdning',
  },
  {
    id: 'service_002',
    area_id: 'area_1',
    name: 'Laddstationer',
    equipment: [],
    type: 'charging_station',
    photo: undefined,
    description: 'Elbilsladdning',
  },
  {
    id: 'service_003',
    area_id: 'area_1',
    name: 'Cykelställ',
    equipment: [],
    type: 'bicycles',
    description: 'Nybyggda cykelställ med tak',
    photo: undefined,
  },
  {
    id: 'service_004',
    area_id: 'area_1',
    name: 'Cykelställ',
    equipment: [],
    type: 'bicycles',
    description: 'Nybyggda cykelställ med tak',
    photo: undefined,
  },
  {
    id: 'service_005',
    area_id: 'area_1',
    name: 'Grillplats',
    equipment: [],
    type: 'fireplace',
    description:
      'Finns stor eldplats med galler. Finns även bänkar för sittplatser.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/7afcbfda-1383-40c4-88a5-8d4d52d7b1cf/attachments/image_20220525101702788.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=7afcbfda-1383-40c4-88a5-8d4d52d7b1cf&express=true',
    booking_resource_id: '7afcbfda-1383-40c4-88a5-8d4d52d7b1cf',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_006',
    area_id: 'area_1',
    name: 'Utegym',
    equipment: [],
    type: 'gym',
    photo:
      'https://lh3.googleusercontent.com/p/AF1QipPb9QB1TeVrGEEzNHhe-OzNqivUstbCM7AT-Dut=s680-w680-h510',
    description: 'Friskvårdsplats utomhus.',
  },
  {
    id: 'service_007',
    area_id: 'area_1',
    name: 'Friskvårdslokal',
    equipment: [],
    type: 'gym',
    photo: undefined,
    description:
      'Friskvårdslokal med flertalet maskiner, vikter, och andra redskap.\nFinns även omklädningsrum och duschar.',
  },
  {
    id: 'service_008',
    area_id: 'area_1',
    name: 'Massagestol',
    equipment: [],
    type: 'relax',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/065938b5-65bd-436e-b6a6-e8c04e44dbac/attachments/image_20220516145729524.jpg',
    description:
      'Superskön massagestol. Placerad på andra våningen i Campus Gräsvik 4, precis vid trappan.',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=065938b5-65bd-436e-b6a6-e8c04e44dbac&express=true',
    booking_resource_id: '065938b5-65bd-436e-b6a6-e8c04e44dbac',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_009',
    area_id: 'area_1',
    name: 'Villa Oscar',
    equipment: [],
    type: 'restaurant',
    photo:
      'https://www.kruthusen.se/wp-content/uploads/2015/12/villa-oskar.jpg',
    description:
      'Områdets favoritlunchställe. Veckans meny finns under menyn "Restaurangmenyer".',
  },
  {
    id: 'service_010',
    area_id: 'area_1',
    name: 'Receptionen',
    equipment: [],
    type: 'admin',
    photo: undefined,
    description: 'Receptionen för hela området Campus Gräsvik.',
  },
  {
    id: 'service_011',
    area_id: 'area_1',
    name: 'Sällskapsyta',
    equipment: ['Pingis', 'Nintendo Switch', 'TV', 'Soffgrupp'],
    type: 'game',
    photo: undefined,
    description:
      'Sugen på lite After Work? Kom hit och häng i sofforna och spela TV-spel eller pingis.',
  },
  {
    id: 'service_012',
    area_id: 'area_1',
    name: 'Båtplats',
    equipment: [],
    type: 'parking',
    description:
      '1 gästhamnsplats (plats nr. 1). Får nyttjas i upp till 12 timmar åt gången.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/e603c3b4-f2e5-45c7-bda5-103b53cbd3df/attachments/image_20230511081544616.jpg',
    booking_resource_id: 'e603c3b4-f2e5-45c7-bda5-103b53cbd3df',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_013a',
    area_id: 'area_1',
    name: 'Kajak 1',
    equipment: ['1st Kajak'],
    type: 'game',
    description: 'Hittas i röda huset vid bryggan, vänstra dörren.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/1eb621e3-06fd-4fbd-9057-4dc925dfb88a/attachments/image_20220704152804352.jpg',
    booking_resource_id: '1eb621e3-06fd-4fbd-9057-4dc925dfb88a',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_013b',
    area_id: 'area_1',
    name: 'Kajak 2',
    equipment: ['1st Kajak'],
    type: 'game',
    description: 'Hittas i röda huset vid bryggan, vänstra dörren.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/1eb621e3-06fd-4fbd-9057-4dc925dfb88a/attachments/image_20220704152804352.jpg',
    booking_resource_id: '69f61e14-1ca1-4317-9268-aef9d15e9a31',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_014',
    area_id: 'area_1',
    name: 'Rullsteken, CG 8',
    equipment: ['Apple TV', 'Skärm', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 4,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/b6fba0a0-14bd-4794-91cf-55f5aca2496f/attachments/image_20220525091219048.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=b6fba0a0-14bd-4794-91cf-55f5aca2496f&express=true',
    booking_resource_id: 'b6fba0a0-14bd-4794-91cf-55f5aca2496f',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_015',
    area_id: 'area_1',
    name: 'Skotsteken, CG 8',
    equipment: ['Projektor', 'Whiteboard'],
    type: 'conference_room',
    guests: 10,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/44171b11-3e50-4761-ae58-5e2e8bf639d0/attachments/image_20220525094106894.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=44171b11-3e50-4761-ae58-5e2e8bf639d0&express=true',
    booking_resource_id: '44171b11-3e50-4761-ae58-5e2e8bf639d0',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_016a',
    area_id: 'area_1',
    name: 'SUP 1',
    equipment: ['1st Stand Up Paddleboard'],
    type: 'game',
    description: 'Hittas i röda huset vid bryggan, vänstra dörren.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/af5bda3d-5eff-4154-9ed5-22f8ed80d558/attachments/image_20220630161342096.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=b6fba0a0-14bd-4794-91cf-55f5aca2496f&express=true',
    booking_resource_id: 'af5bda3d-5eff-4154-9ed5-22f8ed80d558',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_016b',
    area_id: 'area_1',
    name: 'SUP 2',
    equipment: ['1st Stand Up Paddleboard'],
    type: 'game',
    description: 'Hittas i röda huset vid bryggan, vänstra dörren.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/af5bda3d-5eff-4154-9ed5-22f8ed80d558/attachments/image_20220630161342096.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=b6fba0a0-14bd-4794-91cf-55f5aca2496f&express=true',
    booking_resource_id: 'add5ed7d-9467-4e5f-b2f6-e8bef66ae5c8',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_017',
    area_id: 'area_1',
    name: 'Bistro J',
    equipment: [],
    type: 'restaurant',
    photo:
      'https://www.bth.se/wp-content/uploads/2020/02/27012020-DSC04023_klickbar.jpg',
    description:
      'Mysig restaurang nära vattnet som ligger på bottenplan i J-byggnaden på BTH-området.\nVeckans meny finns under menyn "Restaurangmenyer".',
  },
  {
    id: 'service_018',
    area_id: 'area_1',
    name: 'Pålsteken, CG 1',
    equipment: ['Projektor', 'Whiteboard'],
    type: 'conference_room',
    guests: 20,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/4b6eb2e8-83f7-4226-81f0-7093bcbcbf5e/attachments/image_20220525092918809.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=4b6eb2e8-83f7-4226-81f0-7093bcbcbf5e&express=true',
    booking_resource_id: '4b6eb2e8-83f7-4226-81f0-7093bcbcbf5e',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_019',
    area_id: 'area_1',
    name: 'Ankarsteken, CG 1',
    equipment: ['Skärm', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 10,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/e2693010-a143-43c3-aaed-2f920c922a8f/attachments/image_20220525101103099.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=e2693010-a143-43c3-aaed-2f920c922a8f&express=true',
    booking_resource_id: 'e2693010-a143-43c3-aaed-2f920c922a8f',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_020',
    area_id: 'area_1',
    name: 'Tjuvknopen, CG 2',
    equipment: ['Apple TV', 'Skärm', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 4,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/835cd7bc-fca2-4d8c-87da-b8b560300efd/attachments/image_20220525094521962.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=835cd7bc-fca2-4d8c-87da-b8b560300efd&express=true',
    booking_resource_id: '835cd7bc-fca2-4d8c-87da-b8b560300efd',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_021',
    area_id: 'area_1',
    name: 'Robandsknopen, CG 2',
    equipment: ['Apple TV', 'Skärm', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 6,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/51fc0a20-2925-4c64-aff3-d9236c385ca9/attachments/image_20220525094341931.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=51fc0a20-2925-4c64-aff3-d9236c385ca9&express=true',
    booking_resource_id: '51fc0a20-2925-4c64-aff3-d9236c385ca9',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_022',
    area_id: 'area_1',
    name: 'Open Arena, CG 2',
    equipment: ['Apple TV', 'Projektor', 'Whiteboard'],
    type: 'conference_room',
    guests: 100,
    description:
      'Flexibel möblering i anslutning till köket. Ljudanläggning med mic.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/aed0d33a-5b99-4adc-8453-3d53a07e27bc/attachments/image_20220525100737232.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=aed0d33a-5b99-4adc-8453-3d53a07e27bc&express=true',
    booking_resource_id: 'aed0d33a-5b99-4adc-8453-3d53a07e27bc',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_023',
    area_id: 'area_1',
    name: 'Kärleksknopen, CG 2',
    equipment: ['Apple TV', 'Projektor', 'Whiteboard'],
    type: 'conference_room',
    guests: 15,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/dbd2bff0-0ae9-4144-9ce9-71ac7c2684f1/attachments/image_20220525101539224.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=dbd2bff0-0ae9-4144-9ce9-71ac7c2684f1&express=true',
    booking_resource_id: 'dbd2bff0-0ae9-4144-9ce9-71ac7c2684f1',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_024',
    area_id: 'area_1',
    name: 'Fjärilsöga, CG 4',
    equipment: ['Skärm', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 4,
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/b9e07b86-1ea8-4a36-9eb8-a6c9ef5e1b52/attachments/image_20220525101405283.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=b9e07b86-1ea8-4a36-9eb8-a6c9ef5e1b52&express=true',
    booking_resource_id: 'b9e07b86-1ea8-4a36-9eb8-a6c9ef5e1b52',
    connector_driver_type: 'wip',
  },
  {
    id: 'service_025',
    area_id: 'area_1',
    name: 'Valknopen, CG 26',
    equipment: ['Projektor', 'Videokonferens', 'Whiteboard'],
    type: 'conference_room',
    guests: 30,
    description:
      'Del av lunchrummet, med ljudklassad vikvägg. Egen möblering, bord och stolar finns. Standardmöblering är pingisbord och soffa.',
    photo:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/booking-api/calendars/default/resources/public/fadcb4c0-5c41-448e-be57-bbc5a0624637/attachments/image_20220525092040536.jpg',
    qr_link:
      'https://dynapp.wip.se/dynapp-server/public/kruthusen/kruthusen/editor/#/booking/create?resourceIds=fadcb4c0-5c41-448e-be57-bbc5a0624637&express=true',
    booking_resource_id: 'fadcb4c0-5c41-448e-be57-bbc5a0624637',
    connector_driver_type: 'wip',
  },
];

export const ddServices = ddApiServices.map((s) => reviveService(s));
