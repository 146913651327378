import React, { FC, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { LinkProps } from '../../hooks/useLinks';

export const Link: FC<LinkProps> = ({ to, icon, label }) => {
  const match = useMatch(to);
  const isActive = useMemo(() => match?.pathname === to, [match?.pathname, to]);

  return (
    <NavLink
      to={to}
      className={`text-inherit flex items-center gap-[20px] px-[16px] py-[12px] transition-colors active:bg-white/20 ${
        isActive ? 'bg-white/10' : ''
      }`}
    >
      <span className="h-5 w-5">{icon}</span>
      <span className={`text-left ${isActive ? 'font-bold' : ''}`}>
        {label}
      </span>
    </NavLink>
  );
};

export default Link;
