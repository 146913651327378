import { Metadata, reviveMeta } from './metadata';
import { ApiService } from './workaround';

export interface ApiService2 extends ApiService {
  description?: string;
  guests?: number;
  qr_link?: string;
  booking_resource_id?: string;
  connector_driver_type?: string;
}

export const reviveService = (service: ApiService): Service => ({
  ...service,
  // TODO: Make dynamic
  meta: reviveMeta({
    created_at: '2023-01-01T12:00:00.000Z',
    created_by: 'Fritjof',
    updated_at: '2023-01-01T12:00:00.000Z',
  }),
});

export interface Service extends Omit<ApiService2, 'meta'> {
  meta: Metadata;
}
