import { BookableResource } from '../types/bookableResource';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';
import { useConnectors } from './useConnectors';
import { useCallback } from 'react';

export const useBookableResources = (): UseQueryResult<BookableResource[]> => {
  const apiClient = useApiClient();
  const { data: connectors } = useConnectors();

  const fetchAllBookableResources = useCallback(async (): Promise<
    BookableResource[]
  > => {
    const resources: BookableResource[] = [];
    if (!connectors) {
      return resources;
    }
    await Promise.all(
      connectors?.map(async ({ id }) => {
        const newResources = await apiClient.public.connectors.getResources(id);
        resources.push(...newResources);
      }),
    );
    return resources;
  }, [apiClient.public.connectors, connectors]);

  return useQuery({
    queryKey: ['booking', 'resources', connectors],
    queryFn: () => fetchAllBookableResources(),
  });
};
