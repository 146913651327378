import { useMemo } from 'react';
import { Unit } from '../types/unit';
import useConfig from './useConfig';
import { useApartments } from './useApartments';
import { convertToNewUnitSystem } from '../utils/TenantUtils';
import { ddUnits } from '../gallery/dummydata/ddUnits';

export const useUnits = (): { data: Unit[] } => {
  const { organization_id } = useConfig();
  const { data: apartments } = useApartments();

  const units = useMemo((): Unit[] => {
    if (organization_id === 'kruthusen') {
      return ddUnits;
    }

    return convertToNewUnitSystem(apartments).units;
  }, [apartments, organization_id]);

  return { data: units };
};
