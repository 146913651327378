import { ApiMetadata } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';

export interface Metadata {
  created_at: DateTime;
  created_by: string;
  updated_at: DateTime;
}

export function reviveMeta(meta: ApiMetadata): Metadata {
  return {
    created_at: DateTime.fromISO(meta.created_at),
    created_by: meta.created_by,
    updated_at: DateTime.fromISO(meta.updated_at),
  };
}
