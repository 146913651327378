import React, { FC, ReactNode, useMemo } from 'react';
import { Map } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useConfig } from '../../providers/ConfigProvider';
import { useLocation } from '../../hooks/useLocation';

interface Props {
  children: ReactNode;
  zoom?: number;
  onZoom?: (newZoom: number) => void;
}
const NeighborhoodMap: FC<Props> = ({ children, zoom = 15, onZoom }) => {
  const {
    apis: {
      mapbox: { accessToken },
    },
  } = useConfig();

  const { data: location } = useLocation();
  const coordinates = useMemo(() => location?.coordinate, [location]);

  return (
    <Map
      initialViewState={{
        latitude: coordinates?.y,
        longitude: coordinates?.x,
        zoom: zoom,
      }}
      style={{ width: '100%', height: '100%' }}
      mapboxAccessToken={accessToken}
      mapStyle="mapbox://styles/mdjarv/cjte78cxa1wb31fo7xyb90ib9"
      onZoom={(e) => onZoom?.(e.viewState.zoom)}
    >
      {children}
    </Map>
  );
};

export default NeighborhoodMap;
