import { reviveArea } from '../../types/area';
import { ApiArea } from '../../types/workaround';

export const ddApiAreas: ApiArea[] = [
  {
    id: 'area_1',
    name: 'Campus Gräsvik',
    area_geojson: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            service: 'service_005',
          },
          geometry: {
            coordinates: [15.593909919672058, 56.182382018058206],
            type: 'Point',
          },
          id: 0,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_006',
          },
          geometry: {
            coordinates: [15.594374632069616, 56.18173388824178],
            type: 'Point',
          },
          id: 2,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_003',
          },
          geometry: {
            coordinates: [15.592748612965295, 56.1832474632688],
            type: 'Point',
          },
          id: 3,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_008',
          },
          geometry: {
            coordinates: [15.59404526859447, 56.18229600693098],
            type: 'Point',
          },
          id: 4,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_004',
          },
          geometry: {
            coordinates: [15.594192094160945, 56.182170878925945],
            type: 'Point',
          },
          id: 5,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_007',
          },
          geometry: {
            coordinates: [15.593058784318117, 56.18197935567878],
            type: 'Point',
          },
          id: 5,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_009',
          },
          geometry: {
            coordinates: [15.591430310257493, 56.18278477842452],
            type: 'Point',
          },
          id: 7,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_010',
          },
          geometry: {
            coordinates: [15.593047859801487, 56.18286175252305],
            type: 'Point',
          },
          id: 8,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_013a',
          },
          geometry: {
            coordinates: [15.589486411999133, 56.18227180679426],
            type: 'Point',
          },
          id: 9,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_013b',
          },
          geometry: {
            coordinates: [15.589442256877675, 56.1822371131519],
            type: 'Point',
          },
          id: 10,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_012',
          },
          geometry: {
            coordinates: [15.58966703094444, 56.182201096416776],
            type: 'Point',
          },
          id: 13,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_017',
          },
          geometry: {
            coordinates: [15.590389086237991, 56.18284812314383],
            type: 'Point',
          },
          id: 14,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_001',
          },
          geometry: {
            coordinates: [15.593438908041719, 56.18223819451333],
            type: 'Point',
          },
          id: 13,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_002',
          },
          geometry: {
            coordinates: [15.594345321370099, 56.182252697888515],
            type: 'Point',
          },
          id: 14,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_015',
          },
          geometry: {
            coordinates: [15.593474778332649, 56.1840505145926],
            type: 'Point',
          },
          id: 15,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_014',
          },
          geometry: {
            coordinates: [15.593568655527775, 56.183712830856166],
            type: 'Point',
          },
          id: 16,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_019',
          },
          geometry: {
            coordinates: [15.592994262759078, 56.18272117608777],
            type: 'Point',
          },
          id: 17,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_018',
          },
          geometry: {
            coordinates: [15.59288273156119, 56.182834772872496],
            type: 'Point',
          },
          id: 18,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_021',
          },
          geometry: {
            coordinates: [15.593360871334397, 56.18253389913471],
            type: 'Point',
          },
          id: 19,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_020',
          },
          geometry: {
            coordinates: [15.593395456323435, 56.18247856041077],
            type: 'Point',
          },
          id: 20,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_022',
          },
          geometry: {
            coordinates: [15.593662192177277, 56.182418961143384],
            type: 'Point',
          },
          id: 21,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_023',
          },
          geometry: {
            coordinates: [15.593814942545606, 56.18257294719879],
            type: 'Point',
          },
          id: 22,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_024',
          },
          geometry: {
            coordinates: [15.594073430398453, 56.18248348859527],
            type: 'Point',
          },
          id: 22,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_011',
          },
          geometry: {
            coordinates: [15.593142939246661, 56.18224411091467],
            type: 'Point',
          },
          id: 23,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_025',
          },
          geometry: {
            coordinates: [15.59311664662448, 56.18232916652957],
            type: 'Point',
          },
          id: 24,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_016a',
          },
          geometry: {
            coordinates: [15.58942199746977, 56.18229580321167],
            type: 'Point',
          },
          id: 25,
        },
        {
          type: 'Feature',
          properties: {
            service: 'service_016b',
          },
          geometry: {
            coordinates: [15.589377842348256, 56.182262844273026],
            type: 'Point',
          },
          id: 26,
        },
      ],
    },
  },
];

export const ddAreas = ddApiAreas.map((a) => reviveArea(a));
