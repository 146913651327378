import { ApiApartment } from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { reviveApartment } from '../../types/apartment';

export const ddApiApartments: ApiApartment[] = [
  {
    id: 'c40b392f-e41d-4ad6-853a-66bbc4e0d4b0',
    location_id: '91a43e4b-6b49-4bbf-a9a3-6d343d600402',
    meta: {
      created_at: '2023-02-01T12:00:00.000Z',
      updated_at: '2023-02-01T12:00:00.000Z',
      created_by: 'admin',
    },
    organization_id: 'allbinary',
    floor: 0,
    unit: '8001',
    tenants: [
      {
        id: 'd4e1719f-e47b-4366-888b-790f0253459c',
        first_name: 'Anders',
        last_name: 'Andersson',
      },
      {
        id: 'ad0cc301-8fbd-42d5-aaf8-3c1c0decd084',
        first_name: 'Lena',
        last_name: 'Andersson',
      },
    ],
  },
  {
    id: '9376d89b-683e-456c-91d3-6e7e3290484e',
    location_id: '91a43e4b-6b49-4bbf-a9a3-6d343d600402',
    meta: {
      created_at: '2023-02-01T12:00:00.000Z',
      updated_at: '2023-02-01T12:00:00.000Z',
      created_by: 'admin',
    },
    organization_id: 'allbinary',
    floor: 1,
    unit: '8101',
    tenants: [
      {
        id: 'f8a72873-831a-44b6-b963-86e8bedbd6e5',
        first_name: 'Kalle',
        last_name: 'Karlsson',
      },
      {
        id: '707b070f-670c-442c-a4bf-f224ed8baee5',
        first_name: 'Anna',
        last_name: 'Karlsson',
      },
      {
        id: '7a64e546-1047-4c76-92fe-be6d1d5e836c',
        first_name: 'Rune',
        last_name: 'Karlsson',
        active_from: DateTime.now().minus({ days: 1 }).toISO() ?? undefined,
      },
      {
        id: '9ac3fdca-f2a4-4f29-9f83-055e434c441b',
        first_name: 'Pelle',
        last_name: 'Karlsson',
        active_from: DateTime.now().plus({ days: 9 }).toISO() ?? undefined,
      },
      {
        id: '7b8adc43-4773-4c39-97c3-8bbddf496971',
        first_name: 'Lisa',
        last_name: 'Karlsson',
        active_to: DateTime.now().plus({ days: 10 }).toISO() ?? undefined,
      },
      {
        id: '85a238d7-5a53-4f7a-b914-4590a22c1ce6',
        first_name: 'Sara',
        last_name: 'Karlsson',
        active_to: DateTime.now().minus({ days: 10 }).toISO() ?? undefined,
      },
    ],
  },
  {
    id: 'ae33db1a-c1cb-4803-8059-07c34a5fc1fe',
    location_id: '28f4e403-7275-4130-8f8a-36fabe139545',
    meta: {
      created_at: '2023-02-01T12:00:00.000Z',
      updated_at: '2023-02-01T12:00:00.000Z',
      created_by: 'admin',
    },
    organization_id: 'allbinary',
    floor: 2,
    unit: '9201',
    tenants: [
      {
        id: 'aa1ae901-1e26-4962-baee-bdd70716067d',
        first_name: 'Flyttar',
        last_name: 'In',
        active_from: DateTime.now().plus({ days: 1 }).toISO() ?? undefined,
      },
      {
        id: 'd581eacb-edac-4b55-9359-ea10679eb991',
        first_name: 'Flyttar',
        last_name: 'Ut',
        active_to: DateTime.now().plus({ days: 1 }).toISO() ?? undefined,
      },
      {
        id: '2d417cdc-8766-46b9-8fe5-b1444f12e706',
        first_name: 'Flyttar',
        last_name: 'In-Och-Ut',
        active_from: DateTime.now().plus({ days: 1 }).toISO() ?? undefined,
        active_to: DateTime.now().plus({ month: 1 }).toISO() ?? undefined,
      },
      {
        id: '07952bb3-bdcc-47e3-9df3-67b72456a3d4',
        first_name: 'Har Flyttat In',
        last_name: 'Ska Flytta Ut',
        active_from: DateTime.now().minus({ days: 1 }).toISO() ?? undefined,
        active_to: DateTime.now().plus({ month: 1 }).toISO() ?? undefined,
      },
      {
        id: 'c81e634e-ca1d-433f-b680-5386f64198eb',
        first_name: 'Har Flyttat',
        last_name: 'Ut',
        active_to: DateTime.now().minus({ month: 1 }).toISO() ?? undefined,
      },
    ],
  },
];

export const ddApartments = ddApiApartments.map((a) => reviveApartment(a));
