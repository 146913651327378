import React, { FC, useEffect, useMemo, useRef } from 'react';
import ViuLogo from '../icons/ViuLogo';
import QRCode from 'qrcode';
import { useIdentity } from '../providers/IdentityProvider';
import background from '../icons/tenant-viu-background.webp';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useEnv } from '../hooks/useEnv';

const InstallView: FC = () => {
  const queryClient = useQueryClient();
  const { onboarding } = useEnv();
  const { deviceId, deviceType, apiKey } = useIdentity();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const QRString = useMemo(
    () =>
      `${onboarding ?? ''}?type=${deviceType || ''}&id=${deviceId || ''}&psk=${
        apiKey || ''
      }`,
    [deviceId, deviceType, apiKey, onboarding],
  );

  useEffect(() => {
    if (!canvasRef.current) return;

    QRCode.toCanvas(canvasRef.current, QRString, (err) => {
      if (err) {
        throw err;
      }
    });
  }, [QRString]);

  return (
    <main
      className="relative h-full w-full bg-cover"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute inset-0 bg-white/80" />
      <div className="absolute inset-0 bg-green/20" />

      <ViuLogo className="absolute left-24 top-24 h-[150px] w-[215px] text-green-dark" />
      <div className="absolute right-24 top-24">
        <p>
          <FormattedMessage defaultMessage="Serienummer" />
        </p>
        <p>{deviceId}</p>
      </div>
      <div className="absolute bottom-24 right-24">
        <p className="text-center">
          <FormattedMessage defaultMessage="Installera" />
        </p>
        <button
          className="transition-all duration-100 ease-in-out focus:outline-none active:scale-95 active:shadow-none active:outline-none active:ring-0"
          onClick={() => {
            void queryClient.invalidateQueries();
          }}
        >
          <canvas ref={canvasRef} />
        </button>
      </div>
    </main>
  );
};

export default InstallView;
