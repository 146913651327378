import React, { FC, ReactNode } from 'react';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';

/** Provider that will trigger a timeout based on pointer events (a device-agnostic pointer event)
 *  timeout interval can be configured in the config provider
 */

export const InteractionTimeout: FC<{
  timeout: number;
  onTimeout: () => void;
  children?: ReactNode;
}> = ({ timeout, onTimeout, children }) => {
  useInteractionTimeout(timeout, onTimeout);

  return <>{children}</>;
};
