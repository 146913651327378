import { ddServices } from '../gallery/dummydata/ddServices';
import { Service } from '../types/service';
import useConfig from './useConfig';

export const useServices = (): { data: Service[] } => {
  const { organization_id } = useConfig();

  if (organization_id === 'kruthusen') {
    return { data: ddServices };
  }

  return { data: [] };
};
