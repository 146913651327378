import React, { FC } from 'react';
import { useLinks } from '../../hooks/useLinks';
import { BigButtonLink } from './BigButtonLink';

export const BigButtons: FC = () => {
  const links = useLinks();

  if (links.length <= 4) {
    return (
      <div className="flex items-center gap-[40px]">
        {links.map((link) => (
          <BigButtonLink key={link.to} {...link} />
        ))}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[48px]">
      {links.map((link) => (
        <div
          key={link.to}
          className="-mx-[64px] -mt-[176px] flex even:mt-[176px]"
        >
          <BigButtonLink {...link} />
        </div>
      ))}
    </div>
  );
};
