import React, { FC, useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { LinkProps } from '../../hooks/useLinks';

export const BigButtonLink: FC<LinkProps> = ({ icon, label, to }) => {
  const match = useMatch(to);
  const isActive = useMemo(() => match?.pathname === to, [match?.pathname, to]);

  return (
    <NavLink
      to={to}
      className={`bg-blur z-0 flex h-[180px] w-[180px] flex-col 
      items-center justify-center gap-[8px] break-words rounded-full 
       text-gray-dark backdrop-blur-sm ${
         isActive
           ? `scale-110 bg-white text-black transition-all`
           : 'bg-white/60'
       } `}
    >
      <span className="h-[56px] w-[56px]">{icon}</span>
      <span className="max-w-[140px] text-center text-[18px] leading-[20px]">
        {label}
      </span>
    </NavLink>
  );
};
