import {
  ApiApartment,
  ApiApartmentRequest,
  ApiTenant,
} from '@allbin/viu-api-client';
import { DateTime } from 'luxon';
import { Metadata, reviveMeta } from './metadata';

export const reviveApartment = (apartment: ApiApartment): Apartment => ({
  ...apartment,
  meta: reviveMeta(apartment.meta),
  tenants: apartment.tenants.map((tenant) => ({
    ...tenant,
    active_from: tenant.active_from
      ? DateTime.fromISO(tenant.active_from)
      : undefined,
    active_to: tenant.active_to
      ? DateTime.fromISO(tenant.active_to)
      : undefined,
  })),
});

export interface Tenant extends Omit<ApiTenant, 'active_from' | 'active_to'> {
  active_from?: DateTime;
  active_to?: DateTime;
}

export interface Apartment extends Omit<ApiApartment, 'meta' | 'tenants'> {
  meta: Metadata;
  tenants: Tenant[];
}

export interface ApartmentRequest extends Omit<ApiApartmentRequest, 'tenants'> {
  tenants: Tenant[];
}
