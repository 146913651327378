import React, { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useConfig from '../../hooks/useConfig';
import { Departure } from '../../types/publicTransits';

interface Props {
  departure: Departure;
}

export const DepartureRow: React.FC<Props> = ({ departure }) => {
  const {
    departures: { listRelativeTimeThreshold },
  } = useConfig();
  const departureTime = useMemo<ReactNode>(() => {
    const diffNow = departure.departsIn;
    if (
      diffNow.as('milliseconds') > listRelativeTimeThreshold ||
      diffNow.as('milliseconds') < 0 // needed for times past midnight
    ) {
      return departure.time.toLocal().toFormat('HH:mm');
    }

    return (
      <span>
        {Math.round(diffNow.as('minutes'))}
        <span className="ml-1 text-gray-medium font-small">
          <FormattedMessage
            defaultMessage="min"
            description="Short for minutes"
          />
        </span>
      </span>
    );
  }, [departure.departsIn, departure.time, listRelativeTimeThreshold]);

  return (
    <>
      <div className="flex items-center justify-start">
        <span className="rounded-full bg-green px-2 text-white">
          {departure.lineName.substring(0, 3)}
        </span>
      </div>
      <span className="body1">{departure.direction}</span>
      <span className="text-right body1">{departureTime}</span>
    </>
  );
};

export default DepartureRow;
