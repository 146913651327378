import { useCallback, useEffect } from 'react';
import {
  Map,
  map,
  CRS,
  LatLngBounds,
  imageOverlay,
  geoJSON,
  icon,
  marker,
  Layer,
  LatLng,
  Marker,
  circleMarker,
} from 'leaflet';
import { Feature, GeoJsonObject } from 'geojson';
import { TenantCompany, Unit } from '../../types/unit';
import { Floor } from '../../types/floor';
import { useUnits } from '../../hooks/useUnits';
import home from '../../images/home.png';
import meetingRoom from '../../images/meeting-room.png';
import {
  isCurrentlyUsed,
  useBookingResourcesWithEvents,
} from '../../hooks/useBookingResourcesWithEvents';

interface AdvancedLayer extends Layer {
  feature?: Feature;
  _bounds: LatLngBounds;
}

let mapRef: Map | null = null;

const useFloorPlanMap = (
  highlightUnitId: string,
  onUnitClick: (unit?: Unit) => void,
  floor?: Floor,
): void => {
  const { data: units } = useUnits();
  const { data: resources = [] } = useBookingResourcesWithEvents();

  const addLogoToMap = useCallback(
    (options: {
      logo: string;
      x: number;
      y: number;
      unit?: Unit;
      size?: number;
    }): Marker => {
      if (!mapRef) {
        return {} as Marker;
      }

      const { logo, x, y, unit, size = 50 } = options;

      let className = 'rounded-md';
      if (unit && highlightUnitId !== '' && highlightUnitId !== unit?.id) {
        className = 'opacity-50';
      }

      const companyLogo = icon({
        iconUrl: logo,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        className,
      });
      return marker([y, x], { icon: companyLogo, interactive: false }).addTo(
        mapRef,
      );
    },
    [highlightUnitId],
  );

  const addCompanyLogos = useCallback(
    (layer: AdvancedLayer, unit?: Unit): void => {
      if (!unit) {
        return;
      }

      const companies = unit.tenants.filter(
        (t) => t.type === 'company',
      ) as TenantCompany[];
      if (companies.length === 0) {
        return;
      }

      const center = layer._bounds.getCenter();
      if (companies.length === 1 && companies[0].company.logo) {
        addLogoToMap({
          logo: companies[0].company.logo,
          x: center.lng,
          y: center.lat,
          unit,
        });
        return;
      }

      const radius = 50 + unit.tenants.length * 15;
      unit.tenants.forEach((t, i) => {
        if (t.type !== 'company' || !t.company.logo) {
          return;
        }
        const angle = (i / unit.tenants.length) * 2 * Math.PI;
        const x = center.lng + radius * Math.cos(angle);
        const y = center.lat + radius * Math.sin(angle);
        addLogoToMap({ logo: t.company.logo, x, y, unit });
      });
    },
    [addLogoToMap],
  );

  const addGeoJSON = useCallback(
    (geoJson: GeoJsonObject): void => {
      if (!mapRef) {
        return;
      }

      const geoJSONLayer = geoJSON(geoJson, {
        pointToLayer: (feature: Feature, latlng: LatLng) => {
          if (typeof feature.properties?.['wallpad'] !== 'undefined') {
            return addLogoToMap({
              logo: home,
              x: latlng.lng,
              y: latlng.lat,
              size: 30,
            });
          }
          return circleMarker(latlng, { fillOpacity: 0, opacity: 0 });
        },
        style: (feature) => {
          if (feature?.geometry.type !== 'Polygon') {
            return {};
          }
          const unit = units.find(
            (u) => u.id === feature?.properties?.['unit'],
          );
          if (!unit) {
            return { color: '#ff0000', fillOpacity: 1 };
          }
          if (!unit.tenants.length && !unit.booking) {
            return { opacity: 0, fillOpacity: 0 };
          }
          if (unit.booking) {
            const resource = resources.find(
              (r) => r.resource_source_id === unit.booking?.resource_id,
            );
            if (!resource) {
              return { color: '#bbbbbb' };
            }

            return {
              color: isCurrentlyUsed(resource) ? '#ce330c' : '#239929',
              weight: 5,
            };
          }
          if (highlightUnitId !== '') {
            if (highlightUnitId === feature?.properties?.['unit']) {
              return {
                color: '#0ab0f5',
                fillColor: '#0ab0f5',
                fillOpacity: 1,
                className: 'animate-pulse',
                weight: 5,
              };
            } else {
              return {
                color: '#0ab0f5',
                weight: 5,
              };
            }
          }
          return { color: '#0ab0f5', weight: 5 };
        },
      }).addTo(mapRef);

      (geoJSONLayer.getLayers() as AdvancedLayer[]).forEach((layer) => {
        const unit = units.find(
          (u) => u.id === layer.feature?.properties?.['unit'],
        );
        if (!unit || (unit && !unit.tenants.length && !unit.booking)) {
          return;
        }
        layer.on('click', () => {
          onUnitClick(unit);
        });

        if (unit.serviceId) {
          const center = layer._bounds.getCenter();
          addLogoToMap({
            logo: meetingRoom,
            x: center.lng,
            y: center.lat,
            size: 40,
          });
        } else {
          addCompanyLogos(layer, unit);
        }
      });
    },
    [
      addCompanyLogos,
      addLogoToMap,
      highlightUnitId,
      onUnitClick,
      resources,
      units,
    ],
  );

  const initializeMap = useCallback(
    async (url: string, geojson: GeoJsonObject): Promise<Map> => {
      const mapElement = document.getElementById(
        'leafletmap',
      ) as HTMLDivElement;

      const { w, h } = await getImageDimensions(url);

      mapRef = map(mapElement, {
        crs: CRS.Simple,
        minZoom: -3,
        maxZoom: 3,
        zoom: -1,
        zoomSnap: 0.33,
        center: [h, w],
        zoomControl: false,
        attributionControl: false,
      });

      const southWest = mapRef.unproject([0, h], 0);
      const northEast = mapRef.unproject([w, 0], 0);
      const bounds = new LatLngBounds(southWest, northEast);

      imageOverlay(url, bounds).addTo(mapRef);

      mapRef.fitBounds(bounds);

      addGeoJSON(geojson);

      return mapRef;
    },
    [addGeoJSON],
  );

  useEffect(() => {
    if (!floor?.floor_plan || units.length === 0) {
      return;
    }

    const loadMap = async (): Promise<void> => {
      if (!floor.floor_plan || !floor.unit_geojson) {
        return;
      }

      mapRef = await initializeMap(floor.floor_plan, floor.unit_geojson);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadMap();

    return () => {
      if (!mapRef) {
        return;
      }

      mapRef.off();
      mapRef.remove();
    };
  }, [floor, initializeMap, units.length]);
};

const getImageDimensions = (url: string): Promise<{ h: number; w: number }> =>
  new Promise<{ h: number; w: number }>((resolve, reject) => {
    let w = 0,
      h = 0;
    const tempImgElement = document.createElement('img');
    tempImgElement.src = url;
    tempImgElement.onload = () => {
      w = tempImgElement.naturalWidth;
      h = tempImgElement.naturalHeight;
      resolve({ w, h });
    };
    tempImgElement.onerror = () => {
      reject(new Error('Could not load image'));
    };
  });

export default useFloorPlanMap;
