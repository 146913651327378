import { Metadata, reviveMeta } from './metadata';
import { FeatureCollection } from 'geojson';
import { ApiArea } from './workaround';

export interface Area2 extends Omit<Area, 'area_geojson'> {
  area_geojson: FeatureCollection;
}

export const reviveArea = (area: ApiArea): Area2 => ({
  ...area,
  // TODO: Make dynamic
  meta: reviveMeta({
    created_at: '2023-01-01T12:00:00.000Z',
    created_by: 'Fritjof',
    updated_at: '2023-01-01T12:00:00.000Z',
  }),
});

export interface Area extends Omit<ApiArea, 'meta'> {
  meta: Metadata;
}
