import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EmbedIcon } from '../components/EmbedIcon';
import { PageHeader } from '../components/PageHeader';
import { useEmbeddedUrls } from '../hooks/useEmbeddedUrls';

interface Props {
  portrait?: boolean;
}
const EmbeddedUrlView: FC<Props> = ({ portrait }) => {
  const { embeddedUrlId } = useParams();
  const { data, isLoading } = useEmbeddedUrls();
  const embeddedUrl = useMemo(
    () => data?.find((embed) => embed.id === embeddedUrlId),
    [data, embeddedUrlId],
  );

  if (isLoading) {
    return null;
  }

  if (!embeddedUrl) {
    return null;
  }

  return (
    <div className="flex h-full min-w-0 flex-grow flex-col overflow-auto pb-12">
      <PageHeader
        icon={<EmbedIcon icon={embeddedUrl.icon} />}
        title={embeddedUrl.label}
        slim={portrait}
      />
      <iframe
        src={embeddedUrl.url}
        className="inset-0 mx-12 flex h-full items-center justify-center border"
        sandbox="allow-forms allow-scripts allow-same-origin"
      >
        <FormattedMessage defaultMessage="Kunde inte ladda sidan" />
      </iframe>
    </div>
  );
};

export default EmbeddedUrlView;
