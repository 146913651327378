import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AttachmentsView from './views/AttachmentsView';
import InstallView from './views/InstallView';
import PublicTransitView from './views/PublicTransitView';
import TenantRegistryView from './views/TenantRegistryView';
import NavPanel from './components/NavPanel/NavPanel';
import CoveringLoadingScreen from './components/CoveringLoadingScreen';
import { AlertComponent } from './components';
import ErrorBar from './components/ErrorBar';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useIsOffline } from './hooks/useIsOffline';
import { useDeviceOrientation } from './hooks/useDeviceOrientation';
import { InteractionTimeout } from './providers/InteractionTimeout';
import useConfig from './hooks/useConfig';
import * as Sentry from '@sentry/react';
import WeatherView from './views/WeatherView';
import { useIdentity } from './providers/IdentityProvider';
import { IconBuilding, IconCutlery, IconLeaf } from '@allbin/icons';
import { useDevice } from './hooks/useDevice';
import { useLocation } from './hooks/useLocation';
import { useAnnouncements } from './hooks/useAnnouncements';
import { useEmbeddedUrls } from './hooks/useEmbeddedUrls';
import { useAttachments } from './hooks/useAttachments';
import { useApartments } from './hooks/useApartments';
import VerticalTopSection from './components/PortraitTopSection/PortraitTopSection';
import PortraitBottomSection from './components/PortraitBottomSection/PortraitBottomSection';
import FloorPlanView from './views/FloorPlanView';
import ServicesView from './views/ServicesView';
import { useBookableResources } from './hooks/useBookableResources';
import { useBookingResourcesWithEvents } from './hooks/useBookingResourcesWithEvents';
import AttachmentCache from './components/Attachments/AttachmentCache';
import EmbeddedUrlView from './views/EmbeddedUrlView';

const errorMessages = defineMessages({
  offline: { defaultMessage: 'Inget internet' },
  communicationError: { defaultMessage: 'Kan inte uppdatera hyresregister' },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: FC = () => {
  const intl = useIntl();
  const identity = useIdentity(useCallback((state) => state, []));
  const { interactionTimeout } = useConfig();
  const navigate = useNavigate();

  const device = useDevice();
  const location = useLocation();
  const announcements = useAnnouncements();
  const apartments = useApartments();
  const embeddedUrls = useEmbeddedUrls();
  const attachments = useAttachments();
  useBookableResources();
  useBookingResourcesWithEvents();

  const offline = useIsOffline();
  const { portrait } = useDeviceOrientation();

  useEffect(() => {
    if (!identity.id) {
      Sentry.setUser(null);
      return;
    }

    Sentry.setUser({
      ...identity,
      ip_address: '{{auto}}',
    });
  }, [identity]);

  const onInteractionTimeout = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const error = useMemo(() => {
    if (offline) {
      return intl.formatMessage(errorMessages.offline);
    }
    if (
      device.isError ||
      location.isError ||
      announcements.isError ||
      apartments.isError ||
      embeddedUrls.isError ||
      attachments.isError
    ) {
      return intl.formatMessage(errorMessages.communicationError);
    }
    return undefined;
  }, [
    announcements.isError,
    apartments.isError,
    attachments.isError,
    device.isError,
    embeddedUrls.isError,
    intl,
    location.isError,
    offline,
  ]);

  const isLoading = useMemo(
    () =>
      device.isLoading ||
      location.isLoading ||
      announcements.isLoading ||
      apartments.isLoading ||
      embeddedUrls.isLoading ||
      attachments.isLoading,
    [
      announcements.isLoading,
      apartments.isLoading,
      attachments.isLoading,
      device.isLoading,
      embeddedUrls.isLoading,
      location.isLoading,
    ],
  );

  const loadingMessage = useMemo(() => {
    if (!device) {
      return intl.formatMessage({
        defaultMessage: 'Söker efter konfiguration',
      });
    }

    if (isLoading) {
      return intl.formatMessage({ defaultMessage: 'Laddar hyresregister' });
    }
    return undefined;
  }, [device, intl, isLoading]);

  if (isLoading || !device || loadingMessage) {
    return (
      <CoveringLoadingScreen message={loadingMessage} progressBar={!!device} />
    );
  }

  if (device.data?.state !== 'installed') {
    return <InstallView />;
  }

  return (
    <div className="flex h-screen flex-col">
      <div
        className={`flex h-full grow overflow-auto ${
          portrait ? 'flex-col' : 'flex-row'
        }`}
      >
        {portrait ? <VerticalTopSection /> : <NavPanel />}
        <div className="flex h-full w-full overflow-auto">
          <SentryRoutes>
            <Route
              path="/"
              element={
                <div className="flex grow flex-col overflow-hidden">
                  {announcements.data && (
                    <AlertComponent announcements={announcements.data} />
                  )}
                  <TenantRegistryView portrait={portrait} />
                </div>
              }
            />
            <Route
              path="/publictransit"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <PublicTransitView portrait={portrait} />
                </InteractionTimeout>
              }
            />
            <Route
              path="/energydeclarations"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <AttachmentsView
                    icon={<IconLeaf />}
                    title={
                      <FormattedMessage defaultMessage="Energideklaration / OVK" />
                    }
                    attachments={attachments.data?.['energy-declaration-ovk']}
                    portrait={portrait}
                  />
                </InteractionTimeout>
              }
            />
            <Route
              path="/propertyinfo"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <AttachmentsView
                    icon={<IconBuilding />}
                    title={<FormattedMessage defaultMessage="Fastighetsinfo" />}
                    attachments={attachments.data?.['property-info']}
                    portrait={portrait}
                  />
                </InteractionTimeout>
              }
            />
            <Route
              path="/restaurant-menus"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <AttachmentsView
                    icon={<IconCutlery />}
                    title={
                      <FormattedMessage defaultMessage="Restaurangmenyer" />
                    }
                    attachments={attachments.data?.['restaurant-menu']}
                    portrait={portrait}
                  />
                </InteractionTimeout>
              }
            />
            <Route
              path="/documents"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <AttachmentsView
                    title={<FormattedMessage defaultMessage="Dokument" />}
                    attachments={attachments.data?.['other']}
                    portrait={portrait}
                  />
                </InteractionTimeout>
              }
            />
            <Route
              path="/weather"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <WeatherView portrait={portrait} />
                </InteractionTimeout>
              }
            />
            <Route
              path="/embedded-urls/:embeddedUrlId"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <EmbeddedUrlView portrait={portrait} />
                </InteractionTimeout>
              }
            />
            <Route
              path="/floorplan"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <FloorPlanView portrait={portrait} />
                </InteractionTimeout>
              }
            />
            <Route
              path="/services"
              element={
                <InteractionTimeout
                  timeout={interactionTimeout}
                  onTimeout={onInteractionTimeout}
                >
                  <ServicesView portrait={portrait} />
                </InteractionTimeout>
              }
            />
          </SentryRoutes>
        </div>
        {portrait ? <PortraitBottomSection /> : null}
      </div>
      <ErrorBar value={error} />
      <AttachmentCache />
    </div>
  );
};

export default App;
