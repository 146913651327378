import React, { FC, ReactNode, useCallback } from 'react';
import { IconCalendar, IconClock, IconMapPin } from '@allbin/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useBrand } from '../../hooks/useBrand';
import { useLocation } from '../../hooks/useLocation';
import { useCurrentWeather } from '../../hooks/weather/useWeather';
import Time from '../NavPanel/Time';
import { WeatherIcon } from '../../hooks/weather/weather';
import { NewsFeed } from './NewsFeed';
import { useNavigate } from 'react-router-dom';

const VerticalTopSection: FC = () => {
  const queryClient = useQueryClient();
  const { logo } = useBrand();
  const navigate = useNavigate();

  const handleRefresh = useCallback(() => {
    void queryClient.invalidateQueries();
    navigate('/');
  }, [navigate, queryClient]);

  return (
    <div className="flex h-[250px] min-h-[250px] w-full overflow-hidden shadow-md">
      <div className="flex h-full w-[400px] min-w-[400px] flex-col justify-center gap-5 bg-white pb-1">
        <Logo logo={logo} handleRefresh={handleRefresh} />
        <SmallInfoItems />
      </div>

      <NewsFeed />
    </div>
  );
};

interface LogoProps {
  logo: ReactNode;
  handleRefresh: () => void;
}
const Logo: FC<LogoProps> = ({ logo, handleRefresh }) => {
  return (
    <div
      className="flex h-[150px] min-h-[150px] items-center justify-center overflow-hidden"
      onClick={handleRefresh}
    >
      {logo}
    </div>
  );
};

const SmallInfoItems: FC = () => {
  const { data: location, isFetched } = useLocation();
  const currentWeather = useCurrentWeather();

  if (!isFetched || !location) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-1">
      <div className="flex gap-4 px-2">
        <div className="flex max-w-full items-center gap-1">
          <IconMapPin className="h-6 w-6" />
          <span className="line-clamp-1 break-all">
            {location.street || '-'}
          </span>
        </div>
        {currentWeather && (
          <div className="flex items-center gap-1">
            <WeatherIcon
              symbol={currentWeather.symbol}
              className="h-6 w-6 text-white"
            />
            <span>{currentWeather.temperature}&deg;</span>
          </div>
        )}
      </div>
      <div className="flex gap-4">
        <div className="flex items-center gap-1">
          <IconCalendar className="h-6 w-6" />
          <span className="capitalize">
            <Time date />
          </span>
        </div>
        <div className="flex items-center gap-1">
          <IconClock className="h-6 w-6" />
          <Time />
        </div>
      </div>
    </div>
  );
};

export default VerticalTopSection;
