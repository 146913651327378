import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import DepartureRow from './DepartureRow';
import { Departure } from '../../types/publicTransits';

interface Props {
  label: string;
  departures: Departure[];
}

const StopDeparturesList: FC<Props> = ({ label, departures }) => {
  return (
    <div className="flex w-[600px] max-w-full flex-col">
      <div className="flex items-center overflow-hidden h-20">
        <h2 className="mb-5 break-words line-clamp-2 pb-1">{label}</h2>
      </div>
      {departures.length === 0 ? (
        <p className="text-center text-gray-medium">
          <FormattedMessage defaultMessage="Inga avgångar" />
        </p>
      ) : (
        <div className="grid grid-cols-[min-content_1fr_min-content] gap-x-4 gap-y-6">
          <span className="text-gray-medium caption">
            <FormattedMessage defaultMessage="Linje" />
          </span>
          <span className="text-gray-medium caption">
            <FormattedMessage defaultMessage="Destination" />
          </span>
          <span className="text-right text-gray-medium caption">
            <FormattedMessage defaultMessage="Avgår" />
          </span>
          {departures.map((dep, i) => (
            <DepartureRow key={`departure-${i}`} departure={dep} />
          ))}
        </div>
      )}
    </div>
  );
};

export default StopDeparturesList;
