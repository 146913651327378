import { Metadata, reviveMeta } from './metadata';
import { DateTime } from 'luxon';
import {
  ApiUnit,
  ApiUnitTenantCompany,
  ApiUnitTenantIndividual,
} from './workaround';

export interface ApiUnit2 extends Omit<ApiUnit, 'booking'> {
  label: string;
  serviceId?: string;
  booking?: { connector_driver_type: string; resource_id: string };
}

export const reviveUnit = (unit: ApiUnit2): Unit => ({
  ...unit,
  // TODO: Make dynamic
  meta: reviveMeta({
    created_at: '2023-01-01T12:00:00.000Z',
    created_by: 'Fritjof',
    updated_at: '2023-01-01T12:00:00.000Z',
  }),
  tenants: unit.tenants.map((tenant) => ({
    ...tenant,
    active_from: tenant.active_from
      ? DateTime.fromISO(tenant.active_from)
      : undefined,
    active_to: tenant.active_to
      ? DateTime.fromISO(tenant.active_to)
      : undefined,
  })),
});

export interface Unit extends Omit<ApiUnit2, 'meta' | 'tenants'> {
  meta: Metadata;
  tenants: (TenantCompany | TenantIndividual)[];
  label: string;
}

export interface TenantCompany
  extends Omit<ApiUnitTenantCompany, 'active_from' | 'active_to'> {
  active_from?: DateTime;
  active_to?: DateTime;
}

export interface TenantIndividual
  extends Omit<ApiUnitTenantIndividual, 'active_from' | 'active_to'> {
  active_from?: DateTime;
  active_to?: DateTime;
}

export type TenantWithFloor = (TenantCompany | TenantIndividual) & {
  unit_label: string;
  floor_nr: number;
  floor_label: string;
};

export type Demographic = 'companies' | 'individuals' | 'mixed';
