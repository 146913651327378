import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Apartment, reviveApartment } from '../types/apartment';
import { useApiClient } from './useApiClient';
import { useIdentity } from '../providers/IdentityProvider';

export const useApartments = (): UseQueryResult<Apartment[]> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  return useQuery({
    queryKey: ['devices', id, 'apartments'],
    queryFn: ({ queryKey: [, id] }) =>
      apiClient.public.devices
        .getApartments(id ?? '')
        .then((apartments) =>
          apartments.map((apartment) => reviveApartment(apartment)),
        ),
    enabled: true,
  });
};
