import React, { FC } from 'react';
import { Dialog } from '@headlessui/react';
import { NewsArticle } from '../../types/news';
import { Modal } from '../Modal';
import { prettifyDate } from '../../utils/helpers';
import { FormattedMessage } from 'react-intl';

interface Props {
  newsArticle?: NewsArticle;
  show: boolean;
  onClose: () => void;
}

export const NewsArticleModal: FC<Props> = ({ newsArticle, show, onClose }) => {
  if (!show || !newsArticle) {
    return null;
  }

  const { published, image, title, text } = newsArticle;
  const date = new Date(published);

  return (
    <Modal open={true} onClose={onClose} className="w-4/5">
      <div className="flex w-full flex-col gap-3">
        {image ? (
          <div className="mb-7 flex h-96 w-full">
            <img
              src={image}
              alt="news article"
              className="h-full w-full object-cover"
            />
          </div>
        ) : null}
        <Dialog.Title as="h3" className="text-3xl font-medium text-gray-900">
          {title}
        </Dialog.Title>
        <p className="text-gray-700">
          <FormattedMessage defaultMessage="Publicerat" /> {prettifyDate(date)}
        </p>
        <p
          className="pointer-events-none"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </Modal>
  );
};
