import React, { ReactElement, ReactNode } from 'react';
import { classNames } from '../utils/classNames';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'label' | 'type'> {
  /** Defaults to button. */
  type?: 'button' | 'submit' | 'reset';
  label?: ReactNode;
  endIcon?: ReactElement;
  startIcon?: ReactElement;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, endIcon, startIcon, className, children, type, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          `flex min-h-[3rem] items-center justify-center gap-1 overflow-hidden rounded bg-gray-500 px-6 text-white active:bg-gray-300`,
          className,
        )}
        type={type ?? 'button'}
        {...props}
      >
        {startIcon && (
          <span className="flex h-5 max-h-5 w-5 items-center justify-center">
            {startIcon}
          </span>
        )}
        {label ?? children}
        {endIcon && (
          <span className="flex h-5 max-h-5 w-5 items-center justify-center">
            {endIcon}
          </span>
        )}
      </button>
    );
  },
);

export default Button;
