import React, { FC, ReactNode } from 'react';
import {
  IconBicycle,
  IconCalendar,
  IconCutlery,
  IconEvCharge,
  IconFireFlame,
  IconGamepad,
  IconGym,
  IconHealthcare,
  IconHomeTable,
  IconParking,
  IconStar,
  IconWarningTriangle,
} from '@allbin/icons';
import { Service } from '../../types/service';
import { useAreas } from '../../hooks/useAreas';
import { MapMarker } from '../MapMarker';

interface Props {
  service: Service;
  onClick?: () => void;
  hideText?: boolean;
}

const MapServiceMarker: FC<Props> = ({ service, onClick, hideText }) => {
  const { data: areas } = useAreas();

  const area = areas.find((a) => a.id === service.area_id);
  if (!area) {
    return null;
  }

  const feature = area.area_geojson.features.find(
    (f) => f.properties?.['service'] === service.id,
  );

  if (!feature) {
    return null;
  }
  const coordinate = (feature.geometry as { coordinates: number[] })[
    'coordinates'
  ];

  return (
    <MapMarker
      icon={getIcon(service)}
      label={service.name}
      latitude={coordinate[1]}
      longitude={coordinate[0]}
      hideText={hideText}
      onClick={onClick}
    />
  );
};

const getIcon = (service: Service): ReactNode => {
  switch (service.type) {
    case 'charging_station':
      return <IconEvCharge />;
    case 'bicycles':
      return <IconBicycle />;
    case 'fireplace':
      return <IconFireFlame />;
    case 'gym':
      return <IconGym />;
    case 'relax':
      return <IconHealthcare />;
    case 'admin':
      return <IconHomeTable />;
    case 'game':
      return <IconGamepad />;
    case 'restaurant':
      return <IconCutlery />;
    case 'parking':
      return <IconParking />;
    case 'conference_room':
      return <IconCalendar />;
    case 'other':
      return <IconStar />;

    default:
      return <IconWarningTriangle />;
  }
};

export default MapServiceMarker;
