import { ApiUnit2, reviveUnit } from '../../types/unit';

import all_binary from './kruthusen/allbinary.png';
import viu from './kruthusen/viu.png';
import senseStock from './kruthusen/sense_stock.png';
import big_travel from './kruthusen/big_travel_sweden.png';
import blackdrop from './kruthusen/blackdrop.jpg';
import comstream from './kruthusen/comstream.png';
import homemaid from './kruthusen/homemaid.png';
import octacon from './kruthusen/octacon.png';
import axenu from './kruthusen/axenu.jpg';
import enklakassan from './kruthusen/enklakassan.png';
import hillside from './kruthusen/hillside.png';
import joymon from './kruthusen/joymon.png';
import malvacom from './kruthusen/malvacom.png';
import mj_marcable from './kruthusen/MJ_Marcable.png';
import redpill from './kruthusen/redpill.png';
import mutima from './kruthusen/mutima.png';
import minitel from './kruthusen/minitel.png';
import symbistruct from './kruthusen/symbistruct.jpg';
import svavelstickan from './kruthusen/svavelstickan.png';

const active_from = '2023-01-01T12:00:00.000Z';
const active_to = '2030-01-01T12:00:00.000Z';

export const ddApiUnits: ApiUnit2[] = [
  {
    id: 'unit_1005',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0001',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0001',
          name: 'AllBinary',
          contacts: [
            {
              name: 'Eric Anderbjörk',
              email: 'eric@allbinary.se',
              title: 'VD',
            },
            {
              name: 'Tomas Sareklint',
              email: 'tomas@allbinary.se',
            },
          ],
          description:
            'Vi är ett kreativt ingenjörsbolag som drivs av att förenkla interaktionen mellan människor och komplexa hård- och mjukvarusystem. Våra kunder finns främst inom trafik-, energi- och fastighetsbolag där vi utvecklar och säljer produkter som hjälper till att förenkla vardagen för deras kunder och användare. Djupt i våra gener finns en Maker Culture och tro på öppenhet som gör oss mycket snabbfotade och möjliggör anpassningar av våra produkter till nya trender och standarder.',
          logo: all_binary,
        },
      },
      {
        id: 'tenant_company_0001b',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0001b',
          name: 'Viu',
          contacts: [
            {
              name: 'Eric Anderbjörk',
              email: 'eric@allbinary.se',
              title: 'VD',
            },
          ],
          description:
            'I Viu-plattformen är mjuk- och hårdvaror sammankopplade i ett smart trådlöst meshnät för enkel hantering, kommunikation med hyresgästerna och övervakning av fastigheten.\n\nPekskärmar, namnskyltar och sensorer är också sammankopplade med vårt administrativa system för att hålla koll på allt och se till att det fungerar.',
          logo: viu,
        },
      },
      {
        id: 'tenant_company_0001c',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0001c',
          name: 'senseStock',
          contacts: [
            {
              name: 'Mattias',
              email: 'mattias@sensestock.com',
              phone: '0708-472 553',
              title: 'VD',
            },
          ],
          description:
            'Genom att utrusta era lagerplatser med våra vågplattor kan ni få kontroll på saldo, sätta automatiska beställningspunkter och automatisera hela anskaffningsflödet eller bara ha koll internt. Enkelt och sömlöst.',
          logo: senseStock,
        },
      },
    ],
  },
  {
    id: 'unit_1004',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0002',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0002',
          name: 'Big Travel Sweden',
          contacts: [],
          description:
            'Vi erbjuder en heltäckande reseservice till våra avtal kunder affärs- och möten & evenemangsresor från marknadens bredaste utbud inom flyg, tåg, hotell, hyrbilar marktransporter mm. Med mer än 35 års erfarenhet är det vår målsättning att vara det självklara valet för företaget och affärsresenären. Genom att samla marknadens utbud av flyg, tåg, hotell, hyrbilar och mycket mer på ett och samma ställe kan vi erbjuda våra företagskunder bästa möjliga pris och service oavsett typ av resa. Genom våra digitala verktyg ska våra resenärer erbjudas omtanke, engagemang och tillgänglighet och du når oss idag snabbast via chatten.',
          logo: big_travel,
        },
      },
    ],
  },
  {
    id: 'unit_1006',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0003b',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0003b',
          name: 'Octacon',
          contacts: [
            {
              name: 'Max Danielsson',
            },
          ],
          description: 'Konsultarbete med specialicering på spel.',
          logo: octacon,
        },
      },
      {
        id: 'tenant_company_0003a',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0003a',
          name: 'Blackdrop Interactive',
          contacts: [
            {
              name: 'Max Danielsson',
            },
          ],
          description:
            'We have near a decade experience developing medium sized indie games, and also worked on undisclosed AAA games. Maybe yours will be one of them?',
          logo: blackdrop,
        },
      },
      {
        id: 'tenant_company_0003c',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0003c',
          name: 'Svavelstickan',
          contacts: [
            {
              name: 'Max Larsson',
            },
          ],
          description:
            'Superskön spelstudio som utvecklar världssuccén Star Fetchers!',
          logo: svavelstickan,
        },
      },
    ],
  },
  {
    id: 'unit_1002',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0004',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0004',
          name: 'Comstream',
          contacts: [],
          description:
            'A modern company with modern solutions\n\nFounded in 2010, we are a privately held company group with more than 70 employees in our Swedish and Bulgarian offices. By focusing on building long-term partnerships with our customers, we enable them to evolve, manage and run their technical operations.\n\nOur placements in Sweden and Bulgaria allows us to connect two of Europe’s most dynamic IT hubs, where our daily work sees us sourcing, screening, recruiting and placing high-calibre technology professionals for our customers.',
          logo: comstream,
        },
      },
    ],
  },
  {
    id: 'unit_1007',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0005',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0005',
          name: 'Homemaid',
          contacts: [],
          description:
            'Sedan mitten av 90-talet har HomeMaids medarbetare hjälpt tusentals kunder till en renare, mer trivsam vardag genom att serva med hemstädning, företagsstädning, fastighetsstäd, fönsterputs och mycket mer. Detta är den enkla, tydliga idé på vilken Birgitta Josefsson grundade HomeMaid 1997: Att göra vardagen lättare för sina kunder. Det visade sig snabbt vara ett framgångskoncept.',
          logo: homemaid,
        },
      },
    ],
  },
  {
    id: 'unit_1008',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0015',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0015',
          name: 'miniTel',
          contacts: [],
          description:
            'miniTel AB är en Karlskronabaserad lokal leverantör av bredbandsanslutningar i stadsnäten i Blekinge, Småland och Skåne, samt IP-baserade telefonitjänster.\n\nTelefonitjänsterna är tillgängliga i hela Sverige, oaktat vilken leverantör av internettjänster som används.\n\nVi levererar både till privatkunder och till företag, och kan i båda fall hjälpa till med att finna en lösning som passar den specifika kundens behov.',
          logo: minitel,
        },
      },
    ],
  },
  {
    id: 'unit_1001',
    floor_id: 'floor_0001',
    label: '',
    tenants: [],
  },
  {
    id: 'unit_2007',
    floor_id: 'floor_0002',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0008',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0008',
          name: 'Axenu',
          contacts: [
            {
              name: 'Simon Nilsson',
              email: 'simon@axenu.com',
              phone: '0706-758942',
            },
          ],
          description:
            'We aim to build services that can improve your daily life. From a simple tool that saves a few seconds every day, to complex services that allow you to do thing you previously thought impossible\n\nWe believe that it is important to make simple software that is fun to use. Software should never become an obstacle and used efficiently it can make everything simplier. We also believe that the user is the most important part of every project, and therefore we listen to every suggestion you have.',
          logo: axenu,
        },
      },
      {
        id: 'tenant_company_0008b',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0008b',
          name: 'Enklakassan',
          contacts: [
            {
              name: 'Simon Nilsson',
              email: 'simon@axenu.com',
              phone: '0706-758942',
            },
          ],
          description:
            'Med hela din kassa i mobilen, kan du fokusera på att göra det bästa av din butik. Enklakassan tar hand om allt, från köp till bokföring.',
          logo: enklakassan,
        },
      },
      {
        id: 'tenant_company_0008c',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0008c',
          name: 'Joymon',
          contacts: [
            {
              name: 'Simon Nilsson',
              email: 'simon@axenu.com',
              phone: '0706-758942',
            },
          ],
          description:
            'WE ENJOY MONDAYS!\n\nVi bygger inte bara appar, vi bygger relationer.\n\nOavsett om du vill ha något stort eller litet älskar vi att samarbeta med nya partners för att se till att du får en produkt som återspeglar ditt varumärkes personlighet och vision',
          logo: joymon,
        },
      },
    ],
  },
  {
    id: 'unit_2001',
    floor_id: 'floor_0002',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0009',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0009',
          name: 'Hillside Nordic',
          contacts: [],
          description:
            "Vi vill hjälpa företag och IT-organisationer i Norden att bli ännu bättre och effektivare.\nDet vi bidrar med är 'best practices'​ , framtagna koncept baserat på många års samlad erfarenhet från chefsbefattningar, ledande arkitekter och säkerhetsansvariga inom större organisationer så väl Industri och tjänsteföretag som IT-företag.",
          logo: hillside,
        },
      },
    ],
  },
  {
    id: 'unit_2006',
    floor_id: 'floor_0002',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0010',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0010',
          name: 'MJ Marcable Consulting',
          contacts: [],
          description:
            "The Power Cable Industry is changing faster than ever. The global introduction of renewable energy requires new cable solutions and products. These are being developed and qualified by manufacturers, implemented by TSO's and utilized by end-customers.\n\nThe pace of innovation within the Power Cable Industry has outstripped most standards organization's ability to provide the most updated validation of the benefits claimed by manufacturers. International standardization organizations lag typically some years behind.\n\nSo, initially this limits the possibilities for TSO's, NGO's and end customers to fully understand, value and minimize risks and appreciate the benefits of these new technologies.",
          logo: mj_marcable,
        },
      },
    ],
  },
  {
    id: 'unit_2005',
    floor_id: 'floor_0002',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0011',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0011',
          name: 'Malvacom',
          contacts: [
            {
              name: 'Johan Nohave',
              email: 'johan.nohave@malvacom.se',
              title: 'VD',
            },
            {
              name: 'Carina Holmström',
              email: 'carina.holmstrom@malvacom.se',
              title: 'Konsultchef',
            },
          ],
          description:
            'Malvacom är det lilla personliga konsultföretaget som levererar applikationer och serverlösningar till kunder i hela Norden. Vi har några av Sveriges mest erfarna konsulter i vårt team och vi älskar svåra problem och utmaningar! Hos oss kan du få hjälp med alla delar av ditt IT projekt. Vi hjälper ditt företag att omvandla affärsideer och behov till fungerande och effektiva lösningar, och tar hand om drift och underhåll om det önskas. Vi har 25 års erfarenhet och 100-tals projekt bakom oss i alla typer av branscher, så det finns inga uppgifter vi backar för! Bland våra kunder finner du allt från stora multinationella bolag till mindre och mellanstora företag i alla branscher.',
          logo: malvacom,
        },
      },
    ],
  },
  {
    id: 'unit_1003',
    floor_id: 'floor_0001',
    label: '',
    tenants: [
      {
        id: 'tenant_company_0012',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0012',
          name: 'Mutima Group',
          contacts: [{ name: 'Ariton' }],
          description:
            'Vi är en nischad leverantör inom IT. Vi hjälper till med konsultförmedling och permanent rekrytering där vi är mångsidiga mellan olika kompetenskategorier.\n\nVår leverans bygger på kunskap och förståelse för vår marknad, där vårt mål alltid är att leverera snabbt och med precision. Vi tycker att det viktigt med kvalitet och därför är vi uppskattade av våra kunder och konsulter.',
          logo: mutima,
        },
      },
      {
        id: 'tenant_company_0013',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0013',
          name: 'Redpill Linpro Digital Workspace',
          contacts: [{ name: 'Filipa' }],
          description:
            'Som digital samhällsbyggare och open source-specialist bidrar vi till en hållbar samhällsutveckling genom att hjälpa företag öka sin konkurrenskraft och göra digitala tjänster bättre för fler. Genom open source, djup teknisk kompetens och bred erfarenhet från både privat och offentlig sektor hjälper vi våra kunder att digitaliseras och maximera värdet av data. Vår leverans täcker alla delar inom digital innovation, från analys, design och utveckling till integration, användargränssnitt och drift\n\nVi tillhandahåller lösningar inom alla ovanstående områden, baserade på ledande Open Source-teknologier för att säkerställa leverans i tekniskt framkant, som ger våra kunder nya intäktsströmmar, möjlighet att snabbare agera på marknadssvängningar och en effektivare verksamhet.',
          logo: redpill,
        },
      },
      {
        id: 'tenant_company_0014',
        type: 'company',
        active_from,
        active_to,
        company: {
          id: 'company_0014',
          name: 'Symbistruct',
          contacts: [
            {
              name: 'Emric',
              email: 'emric@symbistruct.com',
            },
          ],
          description:
            "Sometimes functionality is rushed without any particular consideration. Quality is compromised and eventually trouble arises. Trouble that shouldn't have occurred. Trouble that costs.\n\nIf you're building a house it must have a stable ground. The same thing goes for software; without a stable ground it won't survive future usage.\n\nYou're the one in charge, which is why we always shape our solutions after your particular needs.\n\nAre you unsure how to proceed? Want to know more? Don't hesitate to get in touch with us.",
          logo: symbistruct,
        },
      },
    ],
  },
  {
    id: 'unit_rullsteken',
    floor_id: 'floor_0001',
    label: 'Rullsteken',
    tenants: [],
    booking: {
      connector_driver_type: 'wip',
      resource_id: 'b6fba0a0-14bd-4794-91cf-55f5aca2496f',
    },
    serviceId: 'service_014',
  },
  {
    id: 'unit_skotsteken',
    floor_id: 'floor_0001',
    label: 'Skotsteken',
    tenants: [],
    booking: {
      connector_driver_type: 'wip',
      resource_id: '44171b11-3e50-4761-ae58-5e2e8bf639d0',
    },
    serviceId: 'service_015',
  },
];

export const ddUnits = ddApiUnits.map((u) => reviveUnit(u));
