import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import StopDeparturesList from '../components/PublicTransit/StopDeparturesList';
import { usePublicTransit } from '../store/usePublicTransit';
import { IconBusStop, IconHomeSimple, IconList, IconMap } from '@allbin/icons';
import { Marker } from 'react-map-gl';
import NeighborhoodMap from '../components/NeighborhoodMap/NeighborhoodMap';
import { MapMarker } from '../components/MapMarker';
import { PageHeader } from '../components/PageHeader';
import { useLocation } from '../hooks/useLocation';
import { Modal } from '../components/Modal';
import Button from '../components/Button';
import { usePublicTransitStops } from '../hooks/usePublicTransitStops';
import { useConfig } from '../providers/ConfigProvider';

interface Props {
  portrait?: boolean;
}
const PublicTransitView: FC<Props> = ({ portrait }) => {
  const {
    departures: { listAmount },
  } = useConfig();
  const { data: location } = useLocation();
  const { data: stops } = usePublicTransitStops();
  const { departures } = usePublicTransit(
    useCallback(({ departures }) => ({ departures }), []),
  );
  const [showMap, setShowMap] = useState(true);
  const [selectedStop, setSelectedStop] = useState('');

  if (!stops) {
    return null;
  }

  return (
    <main className="flex h-full min-w-0 flex-grow flex-col overflow-auto ">
      <PageHeader
        icon={<IconBusStop />}
        title={<FormattedMessage defaultMessage="Kollektivtrafik" />}
        slim={portrait}
        content={
          <Button className="ml-auto" onClick={() => setShowMap(!showMap)}>
            {showMap ? (
              <div className="flex flex-row items-center gap-2">
                <IconList className="h-6 w-6" />
                <FormattedMessage defaultMessage="Tabeller" />
              </div>
            ) : (
              <div className="flex flex-row items-center gap-2">
                <IconMap className="h-6 w-6" />
                <FormattedMessage defaultMessage="Karta" />
              </div>
            )}
          </Button>
        }
      />
      <section className="h-full w-full">
        {showMap ? (
          <NeighborhoodMap>
            {location?.coordinate && (
              <Marker
                latitude={location?.coordinate?.y}
                longitude={location?.coordinate?.x}
              >
                <IconHomeSimple className="h-9 w-9 fill-green-dark" />
              </Marker>
            )}
            {Object.values(stops).map((s) => (
              <MapMarker
                key={s.id}
                icon={<IconBusStop />}
                label={s.name}
                latitude={s.coordinate.lat}
                longitude={s.coordinate.lng}
                onClick={() => setSelectedStop(s.id)}
              />
            ))}
          </NeighborhoodMap>
        ) : (
          <div className="grid grid-cols-1 gap-4 px-10 pb-10 lg:grid-cols-2">
            {Object.values(stops).map((s) => (
              <div key={s.id} className="rounded-lg p-6 ring-4 ring-gray-200">
                <StopDeparturesList
                  label={s.name}
                  departures={departures[s.id]?.slice(0, listAmount) || []}
                />
              </div>
            ))}
          </div>
        )}
      </section>
      <Modal open={!!selectedStop} onClose={() => setSelectedStop('')}>
        <>
          {selectedStop && (
            <StopDeparturesList
              label={stops[selectedStop].name}
              departures={departures[selectedStop]?.slice(0, listAmount) || []}
            />
          )}
        </>
      </Modal>
    </main>
  );
};

export default PublicTransitView;
