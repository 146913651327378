import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCloudSunny } from '@allbin/icons';
import { useWeather } from '../hooks/weather/useWeather';
import { Weather } from '../hooks/weather/weather';
import { DateTime } from 'luxon';
import ContentBox from '../components/ContentBox';
import WeatherWidget from '../components/Weather/WeatherWidget';
import { dayForecast } from '../utils/weather';
import { PageHeader } from '../components/PageHeader';

interface Props {
  portrait?: boolean;
}
export const WeatherView: FC<Props> = ({ portrait }) => {
  const { data } = useWeather();

  const currentForecast = useMemo<Weather[]>(() => {
    if (!data) {
      return [];
    }

    const startTime = DateTime.now()
      .plus({ hour: 1 })
      .set({ minute: 0, second: 0, millisecond: 0 });

    return data.filter((weather) => weather.time >= startTime).slice(0, 6);
  }, [data]);

  const dailyForecast = useMemo<(Weather | undefined)[]>(() => {
    if (!data) {
      return [];
    }

    const today = DateTime.now().startOf('day');
    return [
      dayForecast(today.plus({ day: 1 }), data),
      dayForecast(today.plus({ day: 2 }), data),
      dayForecast(today.plus({ day: 3 }), data),
      dayForecast(today.plus({ day: 4 }), data),
    ];
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <div className="h-full w-full overflow-auto">
      <PageHeader
        icon={<IconCloudSunny />}
        title={<FormattedMessage defaultMessage="Väderprognos" />}
        slim={portrait}
      />
      <main className="flex flex-col gap-8 px-12 pb-10">
        <ContentBox header={<FormattedMessage defaultMessage="Idag" />}>
          <div className="grid grid-cols-6 divide-x divide-gray-light">
            {currentForecast.map((weather) => (
              <section key={weather.time.toISO()} className="w-full">
                <div className="m-4 flex flex-col items-center justify-center gap-8">
                  <header className="text-center text-gray-medium caption">
                    {weather.time.toFormat('HH:mm')}
                  </header>
                  <WeatherWidget weather={weather} />
                </div>
              </section>
            ))}
          </div>
        </ContentBox>
        <div className="grid grid-cols-4 gap-8">
          {dailyForecast.map((weather, i) => (
            <ContentBox
              className="relative grow"
              key={`day-${i}`}
              header={
                <span className="capitalize">
                  {DateTime.now()
                    .plus({ day: i + 1 })
                    .get('weekdayLong')}
                </span>
              }
              contentClassName="p-8 items-center justify-center flex grow"
            >
              {weather ? (
                <WeatherWidget weather={weather} />
              ) : (
                <p className="text-gray-medium caption">
                  <FormattedMessage defaultMessage="Väderinformation saknas" />
                </p>
              )}
            </ContentBox>
          ))}
        </div>
      </main>
    </div>
  );
};

export default WeatherView;
