import { useQuery, UseQueryResult } from '@tanstack/react-query';
import deepmerge from 'deepmerge';

import { ConfigState } from '../store/config';
import { useIdentity } from '../providers/IdentityProvider';
import { useApiClient } from './useApiClient';
import { captureMessage } from '@sentry/react';

const baseConfig: ConfigState = {
  organization_id: 'allbinary',
  locale: 'sv-SE',
  interactionTimeout: 60 * 1000, // 1 minute
  departures: {
    listAmount: 10,
    listUpdateInterval: 10 * 1000, // 10 seconds
    listMinimumTimeCutoff: 60 * 1000, // 1 minute
    listRelativeTimeThreshold: 30 * 60 * 1000, // 30 minutes
  },
  apis: {
    publicTranitsQuery: {
      baseUrl: 'https://v3.gtfs-query.allbin.se/api/v2',
      apiKey: '127fefa36c6e7e35cde4396110cb944382dae914',
      closestStopsLimit: 4,
      departureFetchInterval: 24 * 60 * 60 * 1000, // 24 hours
    },
    mapbox: {
      accessToken:
        'pk.eyJ1IjoibWRqYXJ2IiwiYSI6ImNsYXdpZHMycDBnOTEzd281a3A3OGR4MWIifQ.19yYqK-NNshU1NvXaJXCNw',
    },
  },
  addressId: '9461458d-b891-e711-90f9-005056990590',
  defaultTenantListSortOrder: 'asc',
  defaultTenantListSortOption: 'floor',
};

const useConfig = (): ConfigState & UseQueryResult<ConfigState> => {
  const apiClient = useApiClient();
  const { id } = useIdentity();

  const query = useQuery({
    queryKey: ['config', 'get', id],
    queryFn: async ({ queryKey: [, , id] }) => {
      const config = await apiClient.public.devices.getConfig<{
        organization_id: string;
        config: Partial<ConfigState>;
      }>(id ?? '');

      // Validate config because apiClient will not throw errors on 404 responses
      if (!config.organization_id) {
        captureMessage('missing organization_id');
        throw new Error('missing organization_id');
      }

      return deepmerge<ConfigState, Partial<ConfigState>>(
        {
          ...baseConfig,
        },
        { organization_id: config.organization_id, ...config.config },
        {
          // eslint-disable-next-line
          arrayMerge: (_dst, src) => src,
        },
      );
    },
    enabled: !!id,
    // retry: false,
  });

  return {
    ...query,
    ...(query.data ?? baseConfig),
  };
};

export default useConfig;
