import React, { Fragment, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useAttachment } from '../../hooks/useAttachments';
import { Attachment } from '../../types/attachments';
import { Spinner } from '../Spinner';

interface Props {
  attachment: Attachment;
}

export const AttachmentViewer: React.FC<Props> = ({ attachment }) => {
  const { data } = useAttachment(attachment.id);
  const [numPages, setNumPages] = useState(0);

  const pages = useMemo(
    () => Array.from(new Array<number>(numPages)),
    [numPages],
  );

  const isLoaded = numPages !== 0;

  return (
    <div className="flex grow flex-col overflow-auto px-12 pb-12">
      <article className="flex flex-col items-center">
        <h2 className="mb-2 text-[36px]">
          {attachment.name.replace('.pdf', '')}
        </h2>
        <div
          className={`relative flex w-[708px] rounded bg-gray-light p-1 ${
            isLoaded ? 'h-auto' : 'h-[991px]'
          }`}
        >
          {!isLoaded ? <Spinner className="m-auto" /> : null}
          {data && (
            <Document
              className={`h-full w-full gap-[2px] ${!isLoaded ? 'hidden' : ''}`}
              file={data}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              onLoadError={console.error}
              onSourceError={console.error}
              renderMode="canvas"
            >
              {pages.map((_, index) => (
                <Fragment key={`page_${index + 1}`}>
                  {index !== 0 ? <div className="h-[1px]" /> : null}
                  <Page
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={index + 1}
                    width={700}
                  />
                </Fragment>
              ))}
            </Document>
          )}
        </div>
      </article>
    </div>
  );
};
