import { reviveFloor } from '../../types/floor';
import { ApiFloor } from '../../types/workaround';

import floor1 from './kruthusen/CG8_floor1.png';
import floor2 from './kruthusen/CG8_floor2.png';

export const ddApiFloors: ApiFloor[] = [
  {
    id: 'floor_0002',
    level: 2,
    location_id: 'location_0001',
    floor_plan: floor2,
    level_label: '2',
    unit_geojson: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            unit: 'unit_2005',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [1353.5, -1035],
                [1353.5, -20],
                [2684.52, -20],
                [2684.52, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_2001',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [1353.5, -20],
                [1353.5, -462.875],
                [935.5, -463],
                [935, -431.5],
                [274.5, -430.5],
                [274.5, -686.5],
                [19, -686.5],
                [19.5, -18.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_2006',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [935.5, -1035],
                [935.5, -588.5],
                [1353.5, -588.5],
                [1353.5, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_2007',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [935.5, -1035],
                [935.5, -588.5],
                [717.5, -588],
                [717, -555],
                [274.5, -554],
                [274.5, -686.5],
                [19, -686.5],
                [19.5, -1035],
              ],
            ],
          },
        },
      ],
    },
  },
  {
    id: 'floor_0001',
    level: 1,
    location_id: 'location_0001',
    floor_plan: floor1,
    level_label: 'Entrévåning',
    unit_geojson: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1004',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [934, -1035],
                [934, -619.5],
                [1351, -619.5],
                [1351, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1005',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [1911, -1035.5],
                [1351, -1035.5],
                [1351, -619.5],
                [1911, -619.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_skotsteken',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [2426.5, -429.5],
                [2426.5, -20.5],
                [2682.5, -20.5],
                [2682.5, -429.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1003',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [1351, -429.5],
                [1351, -20],
                [1911, -20],
                [1911, -429.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1002',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [787.5, -429.5],
                [787.5, -20.5],
                [1351, -20.5],
                [1351, -429.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1001',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [787.5, -429.5],
                [787.5, -20.5],
                [19.5, -21],
                [19.5, -238],
                [274, -239.5],
                [274.5, -428.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1007',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [677, -1035],
                [677, -802],
                [934, -802],
                [934, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1008',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [507.5, -1035],
                [507.5, -802],
                [677, -802],
                [677, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_rullsteken',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [274, -1035],
                [274, -802],
                [507.5, -802],
                [507.5, -1035],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            unit: 'unit_1006',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [1911, -619.5],
                [1911, -1035.5],
                [2682.5, -1034.5],
                [2683, -618.5],
                [2521.5, -618.5],
                [2522, -687.5],
                [2317.5, -687.5],
                [2318.5, -618.5],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            wallpad: '',
          },
          geometry: {
            type: 'Point',
            coordinates: [190, -590],
          },
        },
      ],
    },
  },
];

export const ddFloors = ddApiFloors.map((f) => reviveFloor(f));
