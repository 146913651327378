import { Metadata, reviveMeta } from './metadata';
import { ApiFloor } from './workaround';

export const reviveFloor = (floor: ApiFloor): Floor => ({
  ...floor,
  // TODO: Make dynamic
  meta: reviveMeta({
    created_at: '2023-01-01T12:00:00.000Z',
    created_by: 'Fritjof',
    updated_at: '2023-01-01T12:00:00.000Z',
  }),
});

export interface Floor extends Omit<ApiFloor, 'meta'> {
  meta: Metadata;
}
