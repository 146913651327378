import React, { FC, useCallback } from 'react';
import { IconCalendar, IconMapPin } from '@allbin/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useBrand } from '../../hooks/useBrand';
import { useLocation } from '../../hooks/useLocation';
import { useCurrentWeather } from '../../hooks/weather/useWeather';
import Footer from './Footer';
import { NavPanelWeatherWidget } from './NavPanelWeatherWidget';
import DepartsSoonList from '../PublicTransit/DepartsSoonList';
import Time from './Time';
import { useLinks } from '../../hooks/useLinks';
import Link from './Link';
import { useNavigate } from 'react-router-dom';

const NavPanel: FC = () => {
  const queryClient = useQueryClient();
  const { data: location, isFetched } = useLocation();
  const { logo, navbarBackground, navbarColor } = useBrand();
  const currentWeather = useCurrentWeather();
  const links = useLinks();
  const navigate = useNavigate();

  const handleRefresh = useCallback(() => {
    void queryClient.invalidateQueries();
    navigate('/');
  }, [navigate, queryClient]);

  if (!isFetched || !location) {
    return null;
  }

  return (
    <div
      className="flex h-full flex-col overflow-auto !bg-cover !bg-center !bg-no-repeat"
      style={{
        width: '350px',
        minWidth: '350px',
        maxWidth: '350px',
        color: navbarColor,
        background: navbarBackground || undefined,
      }}
    >
      <div
        className="flex h-[144px] min-h-[144px] items-center justify-center overflow-hidden"
        onClick={handleRefresh}
      >
        {logo}
      </div>
      <ul className="my-2 flex flex-col gap-2">
        <li className="flex items-center gap-4 px-4">
          <IconMapPin className="min-w-6 h-6 w-6" />
          <span>{location.street || '-'}</span>
        </li>
        <li className="flex items-center gap-4 px-4">
          <IconCalendar className="min-w-6 h-6 w-6" />
          <span className="capitalize">
            <Time date />
          </span>
        </li>
      </ul>
      <div className="flex items-center justify-center gap-[32px] py-[24px]">
        <h1 className="text-center text-[48px]">
          <Time />
        </h1>
        {currentWeather && <NavPanelWeatherWidget weather={currentWeather} />}
      </div>
      <div
        className="flex grow flex-col overflow-auto"
        style={{
          color: navbarColor,
        }}
      >
        {links.map((link) => (
          <Link key={link.to} {...link} />
        ))}
      </div>
      <DepartsSoonList />
      <Footer />
    </div>
  );
};

export default NavPanel;
