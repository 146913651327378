import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Fuse from 'fuse.js';
import { TenantIndex, TenantList, TenantToolbar } from '../components';
import useTenantRegistryStore from '../store/useTenantRegistryStore';
import {
  sortAndGroupTenants,
  TenantGrouping,
  TenantListSortOption,
} from '../utils/TenantUtils';
import { useInteractionTimeout } from '../hooks/useInteractionTimeout';
import { PageHeader } from '../components/PageHeader';
import { CompanyModal } from '../components/CompanyModal';
import { Company } from '../types/company';
import { useTenants } from '../hooks/useTenants';
import { TenantWithFloor } from '../types/unit';
import { useConfig } from '../providers/ConfigProvider';

const searchOptions: Fuse.IFuseOptions<TenantWithFloor> = {
  fieldNormWeight: 2,
  threshold: 0.3,
  keys: ['first_name', 'last_name', 'company.name'],
};

interface Props {
  portrait?: boolean;
}
const TenantRegistryView: FC<Props> = ({ portrait }) => {
  const { tenants, demographic, tenantLinkProps } = useTenants();
  const {
    sortOption,
    searchValue,
    setSort,
    setSearchValue,
    sortDirection,
    setSortDirection,
  } = useTenantRegistryStore();
  const {
    defaultTenantListSortOrder,
    defaultTenantListSortOption,
    interactionTimeout,
  } = useConfig();

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [listIsScrollable, setListIsScrollable] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const resetView = useCallback(() => {
    if (!sortOption || !sortDirection) {
      return;
    }
    setSort(defaultTenantListSortOption, defaultTenantListSortOrder);
    setSelectedCompany(null);
    setShowCompanyModal(false);

    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }

    if (searchValue) {
      setSearchValue('');
    }
    searchRef.current?.blur();
  }, [
    sortOption,
    sortDirection,
    setSort,
    defaultTenantListSortOption,
    defaultTenantListSortOrder,
    searchValue,
    setSearchValue,
  ]);

  // Reset view after timeout
  useInteractionTimeout(interactionTimeout, resetView);
  useEffect(() => {
    setSort(defaultTenantListSortOption, defaultTenantListSortOrder);
    return () => {
      //Set default sort on unmount
      setSort(defaultTenantListSortOption, defaultTenantListSortOrder);
      // Clear search on unmount
      setSearchValue('');
    };
  }, [
    defaultTenantListSortOption,
    defaultTenantListSortOrder,
    setSearchValue,
    setSort,
  ]);

  const [scrollTo, setScrollTo] = useState('');

  const fuse = useMemo(() => new Fuse(tenants, searchOptions), [tenants]);
  const onSort = useCallback(
    (sortBy: TenantListSortOption) => {
      if (sortOption === sortBy) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        return;
      }
      setSort(
        sortBy,
        sortBy === defaultTenantListSortOption
          ? defaultTenantListSortOrder
          : 'asc',
      );
      setScrollTo('top');
    },
    [setSort, setSortDirection, sortOption, sortDirection],
  );

  // Create fuzzy search and read from Fuse results
  const searchResults: TenantWithFloor[] | null = useMemo(() => {
    if (!searchValue) return null;
    const result = fuse.search(searchValue);
    return result.map((fuseRes) => fuseRes.item);
  }, [searchValue, fuse]);

  // Will trigger on refetching tenants, sorting, searching
  const sortedTenants = useMemo<TenantGrouping[]>(
    () =>
      sortAndGroupTenants(
        searchResults ?? (tenants || []),
        sortOption,
        sortDirection,
      ),
    [searchResults, tenants, sortOption, sortDirection],
  );

  const onListItemClick = useCallback((tenant: TenantWithFloor) => {
    if (tenant.type === 'individual') {
      return;
    }

    const { description, contacts } = tenant.company;
    if (!description && contacts.length === 0) {
      return;
    }

    setSelectedCompany(tenant.company as Company);
    setShowCompanyModal(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('tenantList');
      if (!el) {
        return;
      }

      setListIsScrollable(el.scrollHeight > el.clientHeight);
    }, 1);
  }, [onSort]);

  return (
    <div className="flex grow flex-col overflow-hidden">
      <PageHeader
        icon={tenantLinkProps.icon}
        title={tenantLinkProps.label}
        slim={portrait}
      />
      <TenantToolbar
        onSortClick={onSort}
        sort={sortOption}
        sortDirection={sortDirection}
        onlyCompanies={demographic === 'companies'}
      />
      <div className="flex grow overflow-auto">
        <TenantList
          tenantGroups={sortedTenants}
          scrollTo={scrollTo}
          sort={sortOption}
          ref={listRef}
          onListItemClick={onListItemClick}
          addRightPadding={!listIsScrollable}
        />
        {listIsScrollable ? (
          <TenantIndex
            indices={sortedTenants.map((group) => group.group)}
            onClick={(index) => setScrollTo(index)}
            className="px-2"
          />
        ) : null}
      </div>
      <CompanyModal
        show={!!showCompanyModal}
        companies={selectedCompany ? [selectedCompany] : []}
        onClose={() => setShowCompanyModal(false)}
      />
    </div>
  );
};

export default TenantRegistryView;
