import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SortDirection, TenantListSortOption } from '../utils/TenantUtils';

interface TenantRegistryStore {
  sortOption: TenantListSortOption;
  setSortOption: (sortBy: TenantListSortOption) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  listScrollPosition?: number;
  setListScrollPosition: (position: number | undefined) => void;
  setSortDirection: (direction: SortDirection) => void;
  sortDirection: SortDirection;
  setSort: (
    sortOption: TenantListSortOption,
    sortDirection: SortDirection,
  ) => void;
}

const useTenantRegistryStore = create<TenantRegistryStore>()(
  devtools(
    (set) => ({
      searchValue: '',
      setListScrollPosition: (pos) =>
        set({
          listScrollPosition: pos,
        }),
      setSearchValue: (value) =>
        set({
          searchValue: value,
        }),
      setSortOption: (sortBy) =>
        set({
          sortOption: sortBy,
        }),
      setSortDirection: (sortDirection) => {
        set({
          sortDirection,
        });
      },
      setSort: (sortOption, sortDirection) =>
        set({
          sortOption,
          sortDirection,
        }),
      sortOption: 'floor',
      sortDirection: 'asc',
    }),
    { name: 'register-view-store' },
  ),
);

export default useTenantRegistryStore;
